<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="elevation-1">
            <v-toolbar dark color="primary">
                <v-btn icon dark @click.native="handleModal">
                    <v-icon>fas fa-times-circle</v-icon>
                </v-btn>
                <v-toolbar-title>Generar reporte de cobranza en excel</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <SingleCalendar refElement="startDate" title="Fecha inicio" :input-date.sync="startDate" />
                    </v-col>
                    <v-col cols="6">
                        <SingleCalendar refElement="endDate" title="Fecha fin" :input-date.sync="endDate"
                            :min-date="startDate" />
                    </v-col>
                    <v-col cols="12">
                        <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" v-model="sucursalId"
                            label="Sucursales" required outlined prepend-inner-icon="fas fa-id-card-alt"
                            :readonly="!isAdmin" hint="Seleccione la sucursal. Este campo es opcional" clearable>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click.native="downloadExcelSellDebts">Descargar reporte</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import VentaDataService from '@/service/venta/VentaDataService';
import { getDateLocaleNatural, getToday } from '@/utils/DateUtil';

export default {
  name: 'ExcelExportSellDebts',
  props: {
    dialog: { type: Boolean, default: false },
    handleModal: { type: Function },
    sucursales: { type: Array },
    isAdmin: { type: Boolean, default: false }
  },
  components: { SingleCalendar },
  data() {
    return {
      startDate: '',
      endDate: '',
      sucursalId: SucursalDataService.getCurrentSucursalId()
    }
  },
  methods: {
    downloadExcelSellDebts() {
        const startDate = getDateLocaleNatural(this.startDate) ?? '';
        const endDate = getDateLocaleNatural(this.endDate) ?? '';
        const brandName = this.sucursales.find(brand => brand.id === this.sucursalId)?.nombre ?? '';
        this.$swal.fire({
          title: 'Quieres continuar con la descarga del reporte de cobranza?',
          text: 'El tiempo de descarga puede variar dependiendo de la cantidad de registros',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const params = { startDate, endDate, sucursalId: this.sucursalId };
            try {
              return await VentaDataService.downloadSellDebts(params);
            } catch (error) {
              this.$swal.showValidationMessage(`Petición fallida: ${error}`);
            }
          },
          allowOutsideClick: () => !this.$swal.isLoading()
        }).then( result => {
          if (result.isConfirmed) {
            if( result.value.status == 204 ) {
              this.$swal.fire({ icon: 'info', title: 'Reporte', text: 'No hay registros para descargar', showConfirmButton: false, timer: 1500 });
            } else {
              const url = window.URL.createObjectURL(new Blob([result.value.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Cobranza_${brandName}_${startDate}_${endDate}.xlsx`);
              document.body.appendChild(link);
              link.click();
              link.remove();
              this.$swal.fire({ icon: 'success', title: 'Reporte de cobranza', text: 'Reporte descargado', showConfirmButton: false, timer: 1500 });
            }
          }
        }).catch(err => {
          this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al obtener los datos' + err, showConfirmButton: false, timer: 3500 });
        })
    }
  }
}
</script>